var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nuxt-link",
    {
      staticClass: "project-highlight",
      attrs: { to: "/Projects/" + _vm.project.id },
    },
    [
      _c("img", {
        attrs: { src: _vm.pic_path, alt: "Project Highlight Main Picture" },
      }),
      _vm._v(" "),
      _c("h4", [_vm._v(_vm._s(_vm.project.title))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }