<template>
  <div class="highlights-container">
    <highlight-item
      v-for="project in projects"
      :key="project.id"
      :project="project"
    />
  </div>
</template>

<script>
import HighlightItem from '@/components/Projects/HighlightItem'

export default {
  components: {
    HighlightItem,
  },
  computed: {
    projects() {
      return this.$store.getters.getProjects
    },
  },
}
</script>

<style lang="scss" scoped>
.highlights-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .highlights-container {
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
</style>
