var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "highlights-container" },
    _vm._l(_vm.projects, function (project) {
      return _c("highlight-item", {
        key: project.id,
        attrs: { project: project },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }