<template>
  <nuxt-link :to="'/Projects/' + project.id" class="project-highlight">
    <img :src="pic_path" alt="Project Highlight Main Picture" />
    <h4>{{ project.title }}</h4>
  </nuxt-link>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    pic_path() {
      return '/Projects/' + this.project.short + '/' + this.project.mainpic
    },
  },
}
</script>

<style lang="scss" scoped>
.project-highlight {
  text-align: center;
  text-decoration: none;
  color: #333;
  transition: all 0.4s;
  margin-bottom: 16px;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  h4 {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .project-highlight {
    border: 4px solid transparent;
    padding: 4px;
    margin-bottom: 0;

    img {
      width: 250px;
      height: 250px;
      object-fit: cover;
    }

    &:hover {
      background-color: #efefef;
    }
  }
}
</style>
