<template>
  <div class="team-member">
    <div class="header">
      <span class="member-name">{{ member.fullname }}</span>
      <span class="member-title">{{ member.title }}</span>
    </div>
    <div class="info">
      <img :src="image_path" :alt="'Team Member Picture: ' + member.fullname" />
      <div class="description-container">
        <div class="member-description">
          {{ member.description }}
        </div>
        <div class="additional-info">
          <a :href="'mailto:' + member.email" class="member-mail">{{
            member.email
          }}</a>
          <a :href="pdf_path" target="_blank" class="pdf-button">{{
            gen_click_more
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    member: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gen_click_more() {
      return this.$store.getters.getGeneral('click_more')
    },
    pdf_path() {
      return (
        '/cvs/' +
        this.$store.getters.getCurrentLanguage +
        '/' +
        this.member.short +
        '.pdf'
      )
    },
    image_path() {
      return '/portraits/' + this.member.short + '.jpg'
    },
  },
}
</script>

<style lang="scss" scoped>
.team-member {
  color: #333;
  border-bottom: 2px solid #ccc;
  transition: all 0.4s;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }

  .header {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: calc(100vw - 210px);

    .member-name {
      font-size: 20px;
      font-weight: bold;
      width: 196px;
    }

    .member-title {
      font-weight: bold;
    }
  }

  .info {
    display: flex;
    flex-direction: column;

    img {
      width: 180px;
      height: 180px;
      object-fit: cover;
      align-self: flex-end;
    }

    .description-container {
      display: flex;
      flex-direction: column;
      flex: 1;

      .member-description {
        margin-top: 8px;
        flex: 1;
      }
      .additional-info {
        display: flex;

        .member-mail {
          text-decoration: none;
          margin: 16px 0;
          flex: 1;

          &:hover {
            text-decoration: underline;
          }
        }
        .pdf-button {
          position: absolute;
          top: 136px;
          text-align: center;
          left: 0;
          width: 220px;
          transition: all 0.4s;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          background-color: #1265a7;
          padding: 8px 32px;
          border-radius: 4px;
          color: white;
          cursor: pointer;

          &:hover {
            background-color: darken(#1265a7, 10%);
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .team-member {
    display: flex;
    flex-direction: column;
    padding: 16px;

    &:hover {
      background-color: #efefef;
    }

    .header {
      flex-direction: row;
      position: relative;
      width: auto;
      align-items: flex-end;
    }

    .info {
      flex-direction: row;

      .description-container {
        margin-left: 16px;

        .additional-info {
          .member-mail {
            margin: auto;
            align-self: flex-end;
          }
          .pdf-button {
            position: relative;
            top: auto;
            left: auto;

            &:hover {
              background-color: darken(#1265a7, 10%);
            }
          }
        }
      }
    }
  }
}
</style>
