<template>
  <div id="Header" class="header">
    <div id="MainTop" class="main">
      <div
        id="MobileMenu"
        class="mobile-menu"
        :class="{ open: menuOpen }"
        @click="menuOpen = !menuOpen"
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <nuxt-link to="/" exact>
        <img src="/images/logo.png" alt="Peluso Construction Consortium Logo" />
      </nuxt-link>
    </div>
    <div id="Navi" class="navi">
      <div class="navi-content">
        <div id="MainNavi" class="main-navi">
          <nuxt-link to="/" exact>{{ gen_about }}</nuxt-link>
          <nuxt-link to="/Projects">{{ gen_projects }}</nuxt-link>
          <nuxt-link to="/Teams">{{ gen_project_teams }}</nuxt-link>
        </div>
        <TheLanguage />
      </div>
    </div>
    <transition name="open">
      <div v-if="menuOpen" class="mobile-navigation-container">
        <div
          id="MobileNavigation"
          class="mobile-navigation"
          @click="menuOpen = false"
        >
          <nuxt-link to="/" exact>{{ gen_about }}</nuxt-link>
          <nuxt-link to="/Projects">{{ gen_projects }}</nuxt-link>
          <nuxt-link to="/Teams">{{ gen_project_teams }}</nuxt-link>
        </div>
        <TheLanguage :mobile="true" />
      </div>
    </transition>
  </div>
</template>

<script>
import TheLanguage from '@/components/Navi/TheLanguage.vue'

export default {
  components: {
    TheLanguage,
  },
  data() {
    return {
      menuOpen: false,
    }
  },
  computed: {
    gen_about() {
      return this.$store.getters.getGeneral('about_title')
    },
    gen_projects() {
      return this.$store.getters.getGeneral('projects')
    },
    gen_project_teams() {
      return this.$store.getters.getGeneral('project_teams')
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 4px solid #1265a7;

  .main {
    height: 80px;
    padding: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .mobile-menu {
      width: 32px;
      height: 32px;
      position: relative;
      // transform: rotate(0deg);
      // transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 50%;
        background: #1265a7;
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(even) {
          left: 50%;
          border-radius: 0 4px 4px 0;
        }

        &:nth-child(odd) {
          left: 0px;
          border-radius: 4px 0 0 4px;
        }

        &:nth-child(1),
        &:nth-child(2) {
          top: 0px;
        }

        &:nth-child(3),
        &:nth-child(4) {
          top: 12px;
        }

        &:nth-child(5),
        &:nth-child(6) {
          top: 24px;
        }
      }

      &.open {
        span:nth-child(1),
        span:nth-child(6) {
          transform: rotate(45deg);
        }

        span:nth-child(2),
        span:nth-child(5) {
          transform: rotate(-45deg);
        }

        span:nth-child(1) {
          left: 0;
          top: 4px;
        }

        span:nth-child(2) {
          left: 50%;
          top: 4px;
        }

        span:nth-child(3) {
          left: -50%;
          opacity: 0;
        }

        span:nth-child(4) {
          left: 100%;
          opacity: 0;
        }

        span:nth-child(5) {
          width: 24px;
          left: 0;
          top: 16px;
        }

        span:nth-child(6) {
          left: 50%;
          top: 20px;
        }
      }
    }

    a {
      display: flex;
      align-items: flex-end;

      img {
        height: 60px;
      }
    }
  }

  .navi {
    display: none;
  }

  .mobile-navigation-container {
    display: flex;
    background-color: #1265a7;
    padding: 16px;

    .mobile-navigation {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      a {
        text-decoration: none;
        font-size: 16px;
        color: white;
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a.nuxt-link-active {
        font-weight: bold;
      }
    }
  }
}

.open-enter-active,
.open-leave-active {
  transition: all 0.4s ease-in-out;
  max-height: 200px;
}
.open-enter,
.open-leave-to {
  max-height: 0;
}

@media (min-width: 768px) {
  .header {
    .main {
      height: 120px;
      margin: 0 16px;

      a img {
        height: 100px;
        margin-left: -40px;
      }
    }

    .mobile-menu,
    .mobile-navigation {
      display: none;
    }

    .navi {
      display: block;
      border-top: 4px solid #1265a7;
      padding: 0 16px;

      .navi-content {
        width: 100%;
        margin: 10px auto;
        display: flex;

        .main-navi {
          flex-grow: 1;
        }

        a {
          text-decoration: none;
          font-size: 20px;
          color: #1265a7;
          margin-right: 20px;
        }

        a:hover,
        a.nuxt-link-active {
          text-shadow: 0 0 1px black;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .header .navi .navi-content {
    width: 1024px;
    margin: 10px auto;
  }
}

@media (min-width: 1280px) {
  .header {
    .main {
      width: 1024px;
      margin: 0 auto;

      a img {
        margin-left: -140px;
      }
    }
  }
}
</style>
