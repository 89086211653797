var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter" }, [
    _c("div", { staticClass: "filter-title" }, [
      _vm._v("\n    " + _vm._s(_vm.gen_regions) + "\n  "),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "filter-list" },
      [
        _c(
          "div",
          {
            staticClass: "filter-item",
            class: { active: _vm.selectedRegion === "" },
            on: {
              click: function ($event) {
                return _vm.changeRegion("")
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.gen_regions_all) + "\n    ")]
        ),
        _vm._v(" "),
        _vm._l(_vm.regions, function (region) {
          return _c(
            "div",
            {
              key: region.id,
              staticClass: "filter-item",
              class: { active: _vm.selectedRegion === region.id },
              on: {
                click: function ($event) {
                  return _vm.changeRegion(region.id)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(region.regionname) + "\n    ")]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }