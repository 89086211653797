import general from '~/text/en/generals.json'
import people from '~/text/en/people.json'
import projects from '~/text/en/projects.json'
import regions from '~/text/en/regions.json'
import interactive from '~/text/en/interactive.json'
import deGeneral from '~/text/de/generals.json'
import dePeople from '~/text/de/people.json'
import deProjects from '~/text/de/projects.json'
import deRegions from '~/text/de/regions.json'
import deInteractive from '~/text/de/interactive.json'
import esGeneral from '~/text/es/generals.json'
import esPeople from '~/text/es/people.json'
import esProjects from '~/text/es/projects.json'
import esRegions from '~/text/es/regions.json'
import esInteractive from '~/text/es/interactive.json'

export const state = () => ({
  currentLanguage: 'EN',
  loadedProjects: projects,
  loadedPeople: people,
  loadedRegions: regions,
  loadedLanguages: ['EN', 'DE', 'ES'],
  loadedGeneral: general,
  loadedInteractive: interactive,
  selectedRegion: '',
})

export const actions = {}

export const mutations = {
  changeLanguage(state, lang) {
    state.currentLanguage = lang

    if (process.client) {
      localStorage.setItem('currentLanguage', lang)
    }

    switch (lang) {
      case 'DE':
        state.loadedProjects = deProjects
        state.loadedPeople = dePeople
        state.loadedRegions = deRegions
        state.loadedGeneral = deGeneral
        state.loadedInteractive = deInteractive
        break

      case 'ES':
        state.loadedProjects = esProjects
        state.loadedPeople = esPeople
        state.loadedRegions = esRegions
        state.loadedGeneral = esGeneral
        state.loadedInteractive = esInteractive
        break

      default:
        state.loadedProjects = projects
        state.loadedPeople = people
        state.loadedRegions = regions
        state.loadedGeneral = general
        state.loadedInteractive = interactive
        break
    }
  },
  changeSelectedRegion(state, region) {
    state.selectedRegion = region
  },
}

export const getters = {
  getProject: (state) => (id) => {
    return state.loadedProjects.find((project) => project.id === id)
  },
  getProjects(state) {
    return state.loadedProjects.filter((projects) => projects.active === true)
  },
  getMembers: (state) => (region) => {
    return state.loadedPeople.filter(
      (members) => members.region.id === region && members.active
    )
  },
  getRegions: (state) => {
    return state.loadedRegions
  },
  getCurrentLanguage: (state) => {
    return state.currentLanguage
  },
  getGeneral: (state) => (label) => {
    const ent = state.loadedGeneral.find((gen) => gen.Label === label)

    if (ent) {
      if (ent.is_text) {
        return ent.Content_Text
      }
      return ent.Content_String
    }
    return ''
  },
  getInteractive: (state) => {
    return state.loadedInteractive
  },
}
