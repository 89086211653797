<template>
  <div>
    <TheHeader />
    <nuxt />
  </div>
</template>

<script>
import TheHeader from '@/components/Navi/TheHeader'

export default {
  components: {
    TheHeader,
  },
  mounted() {
    const lang = localStorage.getItem('currentLanguage')

    if (lang !== undefined || lang !== null) {
      this.$store.commit('changeLanguage', 'EN')
    } else {
      this.$store.commit('changeLanguage', lang)
    }
  },
}
</script>

<style lang="scss">
html {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.container {
  margin: 0 10px;
  color: #333;
  padding-bottom: 40px;

  h1 {
    margin: 16px 0;
  }

  p {
    padding-bottom: 8px;
  }
}

@media (min-width: 1024px) {
  .container {
    margin: 0 auto;
    width: 1024px;
    padding: 0 16px;
  }
}

@media (min-width: 1056px) {
  .container {
    padding: 0;
    padding-bottom: 40px;
  }
}
</style>
