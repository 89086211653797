var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header", attrs: { id: "Header" } },
    [
      _c(
        "div",
        { staticClass: "main", attrs: { id: "MainTop" } },
        [
          _c(
            "div",
            {
              staticClass: "mobile-menu",
              class: { open: _vm.menuOpen },
              attrs: { id: "MobileMenu" },
              on: {
                click: function ($event) {
                  _vm.menuOpen = !_vm.menuOpen
                },
              },
            },
            [
              _c("span"),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c("span"),
              _vm._v(" "),
              _c("span"),
            ]
          ),
          _vm._v(" "),
          _c("nuxt-link", { attrs: { to: "/", exact: "" } }, [
            _c("img", {
              attrs: {
                src: "/images/logo.png",
                alt: "Peluso Construction Consortium Logo",
              },
            }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "navi", attrs: { id: "Navi" } }, [
        _c(
          "div",
          { staticClass: "navi-content" },
          [
            _c(
              "div",
              { staticClass: "main-navi", attrs: { id: "MainNavi" } },
              [
                _c("nuxt-link", { attrs: { to: "/", exact: "" } }, [
                  _vm._v(_vm._s(_vm.gen_about)),
                ]),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: "/Projects" } }, [
                  _vm._v(_vm._s(_vm.gen_projects)),
                ]),
                _vm._v(" "),
                _c("nuxt-link", { attrs: { to: "/Teams" } }, [
                  _vm._v(_vm._s(_vm.gen_project_teams)),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("TheLanguage"),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "open" } }, [
        _vm.menuOpen
          ? _c(
              "div",
              { staticClass: "mobile-navigation-container" },
              [
                _c(
                  "div",
                  {
                    staticClass: "mobile-navigation",
                    attrs: { id: "MobileNavigation" },
                    on: {
                      click: function ($event) {
                        _vm.menuOpen = false
                      },
                    },
                  },
                  [
                    _c("nuxt-link", { attrs: { to: "/", exact: "" } }, [
                      _vm._v(_vm._s(_vm.gen_about)),
                    ]),
                    _vm._v(" "),
                    _c("nuxt-link", { attrs: { to: "/Projects" } }, [
                      _vm._v(_vm._s(_vm.gen_projects)),
                    ]),
                    _vm._v(" "),
                    _c("nuxt-link", { attrs: { to: "/Teams" } }, [
                      _vm._v(_vm._s(_vm.gen_project_teams)),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("TheLanguage", { attrs: { mobile: true } }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }