var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.languages.length > 1
    ? _c(
        "div",
        {
          staticClass: "lang-navi",
          class: { mobile: _vm.mobile },
          attrs: { id: "LangNavi" },
        },
        _vm._l(_vm.languages, function (lang) {
          return _c(
            "a",
            {
              key: lang,
              class: { active: _vm.currentLanguage === lang },
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.changeLanguage(lang)
                },
              },
            },
            [_vm._v(_vm._s(lang))]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }