<template>
  <div
    v-if="languages.length > 1"
    id="LangNavi"
    class="lang-navi"
    :class="{ mobile: mobile }"
  >
    <a
      v-for="lang in languages"
      :key="lang"
      href="#"
      :class="{ active: currentLanguage === lang }"
      @click="changeLanguage(lang)"
      >{{ lang }}</a
    >
  </div>
</template>

<script>
export default {
  props: {
    mobile: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage
    },
    languages() {
      return this.$store.state.loadedLanguages
    },
  },
  methods: {
    changeLanguage(lang) {
      this.$store.commit('changeLanguage', lang)
    },
  },
}
</script>

<style lang="scss" scoped>
.lang-navi {
  display: flex;

  &.mobile {
    flex-direction: column;

    a {
      color: white;
    }
  }
}

a {
  text-decoration: none;
  font-size: 20px;
  color: #1265a7;
  margin-left: 20px;

  &.active {
    font-weight: bold;
  }
}
</style>
