var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gallery" },
    [
      _c("CoolLightBox", {
        attrs: { items: _vm.pics, index: _vm.index, slideshow: false },
        on: {
          close: function ($event) {
            _vm.index = null
          },
        },
      }),
      _vm._v(" "),
      _vm.pics.length > 0
        ? _c(
            "div",
            { staticClass: "thumbs" },
            _vm._l(_vm.pics, function (pic, picIndex) {
              return _c("div", {
                key: pic.name,
                staticClass: "thumb",
                class: {
                  active: _vm.selectedPicIndex === picIndex,
                  video: pic.type === "video",
                },
                style: { backgroundImage: "url(" + pic.src + ")" },
                attrs: { alt: "Gallery Thumb " + picIndex },
                on: {
                  click: function ($event) {
                    return _vm.changePic(picIndex)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "image-container" }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedPicIndex > 0,
              expression: "selectedPicIndex > 0",
            },
          ],
          staticClass: "button backward",
          on: { click: _vm.changePicBackward },
        }),
        _vm._v(" "),
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.selectedPicIndex < _vm.pics.length - 1,
              expression: "selectedPicIndex < pics.length - 1",
            },
          ],
          staticClass: "button forward",
          on: { click: _vm.changePicForward },
        }),
        _vm._v(" "),
        _vm.pics[_vm.selectedPicIndex].type === "image"
          ? _c("img", {
              staticClass: "main-image",
              attrs: {
                src: _vm.pics[_vm.selectedPicIndex].src,
                alt: "Main Image",
              },
              on: {
                click: function ($event) {
                  _vm.index = _vm.selectedPicIndex
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.pics[_vm.selectedPicIndex].type === "video"
          ? _c("iframe", {
              attrs: {
                width: "100%",
                height: "100%",
                src:
                  "https://www.youtube.com/embed/" +
                  _vm.pics[_vm.selectedPicIndex].url,
                title: "YouTube video player",
                frameborder: "0",
                allow:
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                referrerpolicy: "strict-origin-when-cross-origin",
                allowfullscreen: "",
              },
            })
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }