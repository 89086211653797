var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "team-member" }, [
    _c("div", { staticClass: "header" }, [
      _c("span", { staticClass: "member-name" }, [
        _vm._v(_vm._s(_vm.member.fullname)),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "member-title" }, [
        _vm._v(_vm._s(_vm.member.title)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "info" }, [
      _c("img", {
        attrs: {
          src: _vm.image_path,
          alt: "Team Member Picture: " + _vm.member.fullname,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "description-container" }, [
        _c("div", { staticClass: "member-description" }, [
          _vm._v("\n        " + _vm._s(_vm.member.description) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "additional-info" }, [
          _c(
            "a",
            {
              staticClass: "member-mail",
              attrs: { href: "mailto:" + _vm.member.email },
            },
            [_vm._v(_vm._s(_vm.member.email))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "pdf-button",
              attrs: { href: _vm.pdf_path, target: "_blank" },
            },
            [_vm._v(_vm._s(_vm.gen_click_more))]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }