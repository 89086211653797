<template>
  <div class="filter">
    <div class="filter-title">
      {{ gen_regions }}
    </div>
    <div class="filter-list">
      <div
        class="filter-item"
        :class="{ active: selectedRegion === '' }"
        @click="changeRegion('')"
      >
        {{ gen_regions_all }}
      </div>
      <div
        v-for="region in regions"
        :key="region.id"
        class="filter-item"
        :class="{ active: selectedRegion === region.id }"
        @click="changeRegion(region.id)"
      >
        {{ region.regionname }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    selectedRegion() {
      return this.$store.state.selectedRegion
    },
    regions() {
      return this.$store.state.loadedRegions
    },
    gen_regions() {
      return this.$store.getters.getGeneral('regions')
    },
    gen_regions_all() {
      return this.$store.getters.getGeneral('regions_all')
    },
  },
  methods: {
    changeRegion(id) {
      this.$store.commit('changeSelectedRegion', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.filter {
  width: 100%;
  border: 2px solid #1265a7;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .filter-title {
    font-size: 20px;
    font-weight: bold;
  }

  .filter-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .filter-item {
      padding: 4px 16px;
      transition: all 0.4s;

      &.active {
        background-color: #999;
      }
    }
  }
}

@media (min-width: 1024px) {
  .filter {
    width: 200px;
    margin-right: 32px;
    padding: 16px;
    align-items: stretch;

    .filter-title {
      margin-bottom: 8px;
    }

    .filter-list {
      flex-direction: column;

      .filter-item {
        padding: 4px;
        width: 100%;
        cursor: pointer;

        &:hover {
          background-color: #ccc;
        }
      }
    }
  }
}
</style>
