<template>
  <div class="gallery">
    <CoolLightBox
      :items="pics"
      :index="index"
      :slideshow="false"
      @close="index = null"
    >
    </CoolLightBox>

    <div v-if="pics.length > 0" class="thumbs">
      <div
        v-for="(pic, picIndex) in pics"
        :key="pic.name"
        :style="{ backgroundImage: 'url(' + pic.src + ')' }"
        class="thumb"
        :class="{
          active: selectedPicIndex === picIndex,
          video: pic.type === 'video',
        }"
        :alt="'Gallery Thumb ' + picIndex"
        @click="changePic(picIndex)"
      />
    </div>
    <div class="image-container">
      <div
        v-show="selectedPicIndex > 0"
        class="button backward"
        @click="changePicBackward"
      ></div>
      <div
        v-show="selectedPicIndex < pics.length - 1"
        class="button forward"
        @click="changePicForward"
      ></div>
      <img
        v-if="pics[selectedPicIndex].type === 'image'"
        class="main-image"
        :src="pics[selectedPicIndex].src"
        alt="Main Image"
        @click="index = selectedPicIndex"
      />
      <iframe
        v-if="pics[selectedPicIndex].type === 'video'"
        width="100%"
        height="100%"
        :src="'https://www.youtube.com/embed/' + pics[selectedPicIndex].url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  components: {
    CoolLightBox,
  },
  props: {
    pics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedPicIndex: 0,
      index: null,
    }
  },
  methods: {
    changePic(pic) {
      this.selectedPicIndex = pic
    },
    changePicForward() {
      this.selectedPicIndex++
    },
    changePicBackward() {
      this.selectedPicIndex--
    },
  },
}
</script>

<style lang="scss" scoped>
.gallery {
  display: flex;
  flex-direction: column;
  width: 100%;

  .thumbs {
    display: grid;
    grid-template-columns: repeat(auto-fit, 12.5%);
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 2px solid #1265a7;

    .thumb {
      cursor: pointer;
      border: 4px solid white;
      height: 70px;
      width: 100px;
      transition: 0.4s all;
      position: relative;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      &.active {
        border-color: #1265a7;

        &.video:after {
          border-left-color: #1265a7;
        }
      }

      &.video:after {
        content: '';
        display: block;
        position: absolute;
        top: 11px;
        left: 26px;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-left: 40px solid white;
        transition: 0.4s all;
      }

      &:hover {
        border-color: lighten(#1265a7, 40%);

        &.video::after {
          border-left-color: lighten(#1265a7, 40%);
        }
      }
    }
  }

  .image-container {
    width: 100%;
    height: 470px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .button {
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -40px;
      background-color: #efefef;
      border-radius: 50%;
      border: 4px solid #efefef;
      width: 80px;
      height: 80px;
      transition: 0.4s all;
      cursor: pointer;

      &:hover {
        border-color: #1265a7;
      }

      &:after {
        content: '';
        position: absolute;
        top: 19px;
        left: 30px;
        width: 0;
        height: 0;
        border-top: 17px solid transparent;
        border-right: 30px solid #efefef;
        border-bottom: 17px solid transparent;
      }

      &:before {
        content: '';
        position: absolute;
        top: 16px;
        left: 15px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-right: 35px solid #1265a7;
        border-bottom: 20px solid transparent;
      }

      &.forward {
        right: 0;
        left: auto;

        &:after {
          border-right: none;
          border-left: 30px solid #efefef;
          left: 13px;
        }
        &:before {
          border-right: none;
          border-left: 30px solid #1265a7;
          left: 25px;
        }
      }
    }

    .main-image {
      object-fit: contain;
      transition: 0.4s all;
      height: 470px;
      cursor: pointer;
    }
  }
}

@media (max-width: 768px) {
  .gallery .thumbs,
  .gallery .image-container .button.forward,
  .gallery .image-container .button.backward {
    display: none;
  }

  .gallery .image-container {
    height: auto;

    .main-image {
      height: auto;
      width: 100%;
    }
  }
}
</style>
