<template>
  <div class="team-group">
    <h3 class="region-title">{{ region.regionname }}</h3>
    <div class="members">
      <TeamMember v-for="member in group" :key="member.id" :member="member" />
    </div>
  </div>
</template>

<script>
import TeamMember from '@/components/Teams/TeamMember'

export default {
  components: {
    TeamMember,
  },
  props: {
    region: {
      type: Object,
      required: true,
    },
  },
  computed: {
    group() {
      return this.$store.getters.getMembers(this.region.id)
    },
  },
}
</script>

<style lang="scss" scoped>
.team-group {
  margin-bottom: 32px;

  .members {
    display: flex;
    flex-direction: column;
  }
}

.region-title {
  border-bottom: 4px solid #1265a7;
  color: #1265a7;
  font-size: 20px;
  margin-bottom: 16px;
}
</style>
