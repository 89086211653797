<template>
  <div class="pic-list">
    <div class="title">Gallery</div>
    <template v-for="pic in pics">
      <img
        v-if="pic.type === 'image'"
        :key="pic.name"
        :src="pic.src"
        :alt="'Gallery Pic ' + pic.name"
        @click="select(pic)"
      />
      <iframe
        v-if="pic.type === 'video'"
        :key="pic.name"
        width="100%"
        height="400px"
        :src="'https://www.youtube.com/embed/' + pic.url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </template>
    <transition name="fade">
      <div v-if="selectedImage" class="cover">
        <div class="close" @click="closeOverlay"></div>
        <div id="cont" class="image-container" @scroll="handleScroll">
          <img
            class="selected-image"
            :src="selectedImage.src"
            alt="zoomed in picture"
          />
        </div>
        <div class="info-text" :class="{ hidden: scrolled }">
          scroll &#8594;
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    pics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedImage: '',
      scrolled: false,
    }
  },
  methods: {
    select(pic) {
      this.selectedImage = pic
    },
    closeOverlay() {
      this.selectedImage = ''
      this.scrolled = false
    },
    handleScroll(event) {
      const container = document.getElementById('cont')
      if (container.scrollLeft > 20) {
        this.scrolled = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pic-list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 8px;
  }

  > img {
    width: 100%;
    margin-bottom: 16px;
  }
}

.cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 41px;
      width: 2px;
      background-color: white;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .info-text {
    color: white;
    width: 100%;
    position: absolute;
    right: 5vw;
    bottom: 2vh;
    display: flex;
    justify-content: flex-end;
    transition: opacity 1s;

    &.hidden {
      opacity: 0;
    }
  }
}

.image-container {
  position: absolute;
  top: 10vh;
  left: 2vw;
  right: 2vw;
  bottom: 5vh;
  overflow-y: hidden;
  overflow-x: scroll;

  .selected-image {
    height: 100%;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media (min-width: 768px) {
  .pic-list {
    display: none;
  }
}
</style>
