var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pic-list" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("Gallery")]),
      _vm._v(" "),
      _vm._l(_vm.pics, function (pic) {
        return [
          pic.type === "image"
            ? _c("img", {
                key: pic.name,
                attrs: { src: pic.src, alt: "Gallery Pic " + pic.name },
                on: {
                  click: function ($event) {
                    return _vm.select(pic)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          pic.type === "video"
            ? _c("iframe", {
                key: pic.name,
                attrs: {
                  width: "100%",
                  height: "400px",
                  src: "https://www.youtube.com/embed/" + pic.url,
                  title: "YouTube video player",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                  referrerpolicy: "strict-origin-when-cross-origin",
                  allowfullscreen: "",
                },
              })
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.selectedImage
          ? _c("div", { staticClass: "cover" }, [
              _c("div", {
                staticClass: "close",
                on: { click: _vm.closeOverlay },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "image-container",
                  attrs: { id: "cont" },
                  on: { scroll: _vm.handleScroll },
                },
                [
                  _c("img", {
                    staticClass: "selected-image",
                    attrs: {
                      src: _vm.selectedImage.src,
                      alt: "zoomed in picture",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "info-text", class: { hidden: _vm.scrolled } },
                [_vm._v("\n        scroll →\n      ")]
              ),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }